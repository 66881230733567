<template>
	<div>
		<div class="top-cover">
			<img src="../../assets/common/cover_3.png" alt>
		</div>
		<div class="operation csc">
			<t-title title="操作流程" eng="OPERATION PROCESS" />
		
			<div class="section">
				<div class="card rsc" v-for="item in data">
					<div class="item cover">
						<img style="width: 100%;" :src="item.thumb">
					</div>
					<div class="item cont">
						<div class="title">{{item.title}}</div>
						<div>{{item.intro}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TTitle from 'components/title'
	export default {
		components: {
			TTitle,
		},
		data() {
			return {
				data:[]
			}
		},
		mounted() {
			this.homeIndex()
		},
		methods:{
			async homeIndex(){
				let {operation_article}=await this.$api.homeIndex()
				this.data=operation_article
			}
		}
	}
</script>

<style lang="scss">
	.top-cover {
		width: 100%;
		height: 450px;
		background: #ededed;

		img {
			max-width: 1920px;
			min-width: 1200px;
			width: 100%;
			height: 100%;
			display: block;
			margin: 0 auto;
		}
	}
	
	.operation {
		width: 100%;
		background: #fff;
		padding: 80px 0;
	
		.section {
			width: 1000px;
			margin-top: 70px;
	
			.card {
				width: 100%;
				height: 350px;
				background: rgba(244, 244, 244, 0.9);
				margin-bottom: 20px;
	
				.item {
					height: 100%;
					width: 50%;
					overflow: hidden;
					position: relative;
	
					.play-icon {
						width: 120px;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						z-index: 99;
					}
				}
	
				.cont {
					box-sizing: border-box;
					padding: 20px;
					overflow: hidden;
	
					.title {
						width: 74px;
						font-size: 1.1rem;
						font-weight: bold;
						margin-bottom: 10px;
	
						&::after {
							content: '';
							display: block;
							width: 100%;
							height: 3px;
							background: #3F7CF8;
	
						}
					}
	
					.txt {
						width: 100%;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 7;
						overflow: hidden;
					}
				}
			}
		}
	}
</style>
